exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-netlify-forms-tsx": () => import("./../../../src/pages/netlifyForms.tsx" /* webpackChunkName: "component---src-pages-netlify-forms-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-case-tsx": () => import("./../../../src/templates/case.tsx" /* webpackChunkName: "component---src-templates-case-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-person-tsx": () => import("./../../../src/templates/person.tsx" /* webpackChunkName: "component---src-templates-person-tsx" */),
  "component---src-templates-play-tsx": () => import("./../../../src/templates/play.tsx" /* webpackChunkName: "component---src-templates-play-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

